import axios from 'axios'
const API = {
    development: 'http://localhost:4001/prometeo/',
    production: `${process.env.REACT_APP_API_NODE}`
}

const baseUrlApi = API[process.env.REACT_APP_STAGE]

// export const node = axios.create({
//     baseURL: baseUrlApi,
//     headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json'
//     }
// })

export const elmer = axios.create({
    baseURL: `${baseUrlApi}session`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export const dotnet = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
})
dotnet.interceptors.request.use((config) => {
    console.log(sessionStorage.getItem('token'))
    config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`
    config.headers['Access-Control-Allow-Origin'] = '*'
    return config
})
dotnet.interceptors.response.use(
    config => config,
    error => {
        if (error.response && error.response.status === 401 && error.response.data === 'Token expirado') {
            sessionStorage.removeItem('token')
            window.location.href = '/'
        }
        return Promise.reject(error)
    }
)
export const noheader = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`
})
export const GoogleData = {
    client_ID: `${process.env.REACT_APP_CLIENT_ID}`
}
