import styled from 'styled-components'
export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.style?.backgroundImage ?? props.theme.colors.background};
`
export const Text = styled.p`
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 3%;
`
