import styled, { keyframes } from 'styled-components'
import { ProSidebar, SidebarHeader, SidebarContent } from 'react-pro-sidebar'
import {
    FiGrid,
    FiFileText,
    FiStar,
    FiFolder,
    FiList,
    FiUser,
    FiLogOut,
    FiSettings,
    FiChevronsLeft,
    FiChevronsRight,
    FiLock,
    FiHome,
    FiEye,
    FiActivity,
    FiAlertCircle,
    FiSun,
    FiMoon,
    FiUsers
} from 'react-icons/fi'
import {
    FaUmbrellaBeach
} from 'react-icons/fa6'
import { VscGraphLine } from 'react-icons/vsc'
import { RiCodeView } from 'react-icons/ri'
import { BsEasel } from 'react-icons/bs'
import { shade } from 'polished'
import ReactBadge from 'react-bootstrap/Badge'

const shake = keyframes`
10% , 40% {
    transform: translate3d(0px, 0, 0);
  }
  42% {
    transform: translate3d(-7px, 0, 0);
  }
  44% {
    transform: translate3d(0px, 0, 0);
  }
  46% {
    transform: translate3d(-7px, 0, 0);
  }
  48% {
    transform: translate3d(0px, 0, 0);
  }
  50% {
    transform: translate3d(-7px, 0, 0);
  }
  52% {
    transform: translate3d(0px, 0, 0);
  }
  54% {
    transform: translate3d(-7px, 0, 0);
  }
  60% , 100{
    transform: translate3d(0px, 0, 0);
  }
`
export const SCSidebar = styled(ProSidebar)`

    .pro-sidebar {
        @media (max-width: 1366px) {
            zoom: 0.7 !important;
        }
    }
    .pro-sidebar-layout{
        background-color: ${props => props.theme.title === 'dark' ? shade(0.2, props.theme.colors.background) : props.theme.colors.primary} !important;
        color: '#FFF';
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &hover{
            color: ${props => props.theme.colors.color};
        }
    }

    .pro-item-content {
        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }

    .pro-sidebar-content {
        background-color: ${props => props.theme.title === 'dark' ? shade(0.2, props.theme.colors.background) : props.theme.colors.primary} !important;
        color: ${props => props.theme.colors.color};
    }



    .pro-inner-item {
        &:hover {
            color: #FFF;
            background-color: ${props => props.theme.colors.secondary};
            transition: all .2s ease-in-out;
        }
        &:active {
            color: #FFF;
            background-color: ${props => props.theme.colors.secondary};
            transition: all .2s ease-in-out;
        }
        &:focus {
            color: #FFF;
            background-color: ${props => props.theme.colors.secondary};
            transition: all .2s ease-in-out;
        }
    }

    .pro-item-content {
        color: ${props => props.theme.colors.color};
        &:hover {
            color: #FFF;
            transition: all .2s ease-in-out;
        }
        &:active {
            color: #FFF;
            transition: all .2s ease-in-out;
        }
        &:focus {
            color: #FFF;
            transition: all .2s ease-in-out;
        }
    }

    .pro-menu.shaped.circle > .pro-inner-item{
        color: ${props => props.theme.colors.color}
    }

    .pro-icon-wrapper {
        background-color: ${props => props.theme.colors.background} !important;
    }

    .pro-icon {
        background-color: ${props => props.theme.title === 'light' && props.theme.colors.primary};
    }

    .pro-sub-menu > .pro-inner-list-item {
        background-color: ${props => props.theme.colors.background} !important;
    }

    .popper-inner {
        background-color: ${props => props.theme.colors.background} !important;
    }

`

export const HeaderSidebar = styled(SidebarHeader)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: calc(2% + 16px);
    border-bottom: transparent !important;
`

export const SCSidebarContent = styled(SidebarContent)`
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`

export const TextName = styled.h1`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Bold';
    font-size: 20px;
    margin: 0 0 0;
`

export const TextLastname = styled.h3`
    text-transform: uppercase;
    font-family: 'Light';
    font-size: 16px;
`

export const IconHome = styled(FiHome)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconProject = styled(BsEasel)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconDev = styled(RiCodeView)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconUsability = styled(VscGraphLine)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconGrid = styled(FiGrid)`
    color: #a76ab6;
    font-size: 20px;
`
export const IconFlex = styled(FaUmbrellaBeach)`
    color: #a76ab6;
    font-size: 20px;
`
export const IconSol = styled(FiAlertCircle)`
color: #a76ab6;
font-size: 20px;
`
export const Count = styled.div`
color: ${props => props.theme.colors.color};
font-size: 20px;
`
export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
font-size: 15px;
`

export const IconReports = styled(FiFileText)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconStar = styled(FiStar)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconFolder = styled(FiFolder)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconList = styled(FiList)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconCollapsed = styled(FiChevronsLeft)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconUncollapsed = styled(FiChevronsRight)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconUser = styled(FiUser)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconSettings = styled(FiSettings)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconLogout = styled(FiLogOut)`
    color: #FF2424;
    font-size: 20px;
`

export const IconLock = styled(FiLock)`
    color: #a76ab6;
    font-size: 20px;
`

export const IconEye = styled(FiEye)`
    color: #a76ab6;
    font-size: 20px;
`
export const IconActivity = styled(FiActivity)`
    color: #a76ab6;
    font-size: 20px;
`
export const IconSun = styled(FiSun)`
    color: #a76ab6;
    font-size: 20px;
`
export const IconMoon = styled(FiMoon)`
    color: #a76ab6;
    font-size: 20px;
`
export const IconUsers = styled(FiUsers)`
color: #a76ab6;
font-size: 20px;
`
export const Badge = styled(ReactBadge)`
    font-size: 100%;
    // border: 1px solid ${props => props.theme.colors.color};
    animation: ${shake} 10s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
`
