import { createGlobalStyle } from 'styled-components'
import { Regular, Bold, Italic, Light } from 'src/assets/fonts'

export default createGlobalStyle`
    @font-face {
        font-family: 'Regular';
        src: url(${Regular});
        font-display: swap;
    }

    @font-face {
        font-family: 'Bold';
        src: url(${Bold});
        font-display: swap;
    }

    @font-face {
        font-family: 'Italic';
        src: url(${Italic});
        font-display: swap;
    }

    @font-face {
        font-family: 'Light' ;
        src: url(${Light});
        font-display: swap;
    }

    *{
        padding: 0;
        margin: 0;
        font-size: 14px;
        @media screen and (max-width: 1280px) {
            font-size: 10px;
        }

    }

    html, body, #root, .app {
        height: 100%;
    }

    body{
        font-size: 18px;
        @media screen and (max-width: 1280px) {
            font-size: 10px;
        }
        color: ${props => props.theme.colors.color};
        font-family: 'Regular';
    }
    .Embed-container {
        height: 500px;
        width: 900px;
      }
    .Container{
        width: 100%;
    }
    .pro-sidebar {
        @media (max-width: 1366px) {
            zoom: 0.7 !important;
        }
    }
`
