/* eslint-disable react/no-deprecated */
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import AuthProvider from './context/Auth'
import StageProvider from './context/Stage'
import { BrowserRouter as Router } from 'react-router-dom'
import './consts'
import 'bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/noty/lib/noty.css'
import './../node_modules/noty/lib/themes/bootstrap-v4.css'
import './app.scss'
ReactDOM.render(
    <React.StrictMode>
        <StageProvider>
            <Router basename="/">
                <AuthProvider>
                    <App />
                </AuthProvider>
            </Router>
        </StageProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
