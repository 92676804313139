/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react'

const StageContext = createContext()

export default function AuthProvider ({ children }) {
    const [stage, setStage] = useState(process.env.REACT_APP_ENVIRONMENT) //eslint-disable-line

    return (
        <StageContext.Provider
            value={{
                stage
            }}
        >
            { children }
        </StageContext.Provider>
    )
}

export function useStage () {
    const {
        stage
    } = useContext(StageContext)

    return {
        stage
    }
}
