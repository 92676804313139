/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SidebarFooter, Menu, MenuItem } from 'react-pro-sidebar'
import { IconUser, IconLogout, IconSun, IconMoon } from './styles'
import { useAuth } from './../../context/Auth'
import { Notification } from 'src/components/Notification'
import { dotnet } from '../../consts'
const Footer = () => {
    const { push } = useHistory()
    const { userData, setUserData } = useAuth()
    const [isDarkTheme, setIsDarkTheme] = useState(false)

    useEffect(() => {
        if (userData) {
            setIsDarkTheme(userData.USER_CONFIG.THEME === 'dark')
        }
    }, [userData])
    const logout = async () => {
        const params = {
            type: 'warning',
            text: '¿Estás seguro de querer cerrar sesión?',
            confirmDialog: {
                confirmText: 'Si, Cerrar sesión',
                actionConfirm: async () => {
                    try {
                        push('/cerrando-sesion')
                    } catch (error) {
                        Notification({
                            type: 'error',
                            text: 'Ha ocurrido un error.'
                        })
                    }
                }
            }
        }
        Notification(params)
    }

    const setTheme = useCallback(async () => {
        const themeTitle = !isDarkTheme ? 'dark' : 'light'
        try {
            await dotnet.put(`prometeo/user-configs/theme/${userData.ID_USER}`, { THEME: themeTitle })
            setUserData({
                ...userData,
                USER_CONFIG: {
                    ...userData.USER_CONFIG,
                    THEME: themeTitle
                }
            })
        } catch (error) {
            console.log(error)
            Notification({
                type: 'error',
                text: 'Ha ocurrido un error.'
            })
        }
    }, [userData, setUserData, isDarkTheme])
    return (
        <SidebarFooter>
            <Menu iconShape="circle">
                <MenuItem
                    icon={userData.USER_CONFIG.THEME === 'dark' ? <IconSun /> : <IconMoon />}
                    onClick={setTheme}
                    title='Cambiar tema'
                >
                    Cambiar tema
                </MenuItem>
                <MenuItem
                    icon={<IconUser />}
                >
                    {userData.NOMBRES}
                </MenuItem>
                <MenuItem
                    icon={<IconLogout />}
                    title='Cerrar Sesión'
                    onClick={logout}
                >
                    Cerrar Sesión
                </MenuItem>
            </Menu>
        </SidebarFooter>
    )
}

export default Footer
