import { lazy } from 'react'

export const publicRoutes = [
    {
        name: 'login',
        path: '/',
        component: lazy(() => import('./login'))
    },
    {
        name: 'expired-session',
        path: '/expired-session',
        component: lazy(() => import('./expired-session'))
    },
    {
        name: 'error-page',
        path: '/error-page',
        component: lazy(() => import('./error-page'))
    },
    {
        name: 'cerrando-sesion',
        path: '/cerrando-sesion',
        component: lazy(() => import('./cerrando-sesion'))
    }
]

export const privateRoutes = [
    {
        name: 'users',
        path: '/users',
        component: lazy(() => import('./users'))
    },
    {
        name: 'solicitud',
        path: '/solicitud',
        component: lazy(() => import('./solicitud'))
    },
    {
        name: 'reset-password',
        path: '/reset-password',
        component: lazy(() => import('./reset-password'))
    },
    {
        name: 'solicitud-responder',
        path: '/solicitud/responder',
        component: lazy(() => import('./solicitud/responder'))
    },
    {
        name: 'home',
        path: '/home',
        component: lazy(() => import('./home'))
    },
    {
        name: 'apps-home',
        path: '/apps/home',
        component: lazy(() => import('./apps/home'))
    },
    {
        name: 'news',
        path: '/news',
        component: lazy(() => import('./news'))
    }
]
