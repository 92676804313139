import React from 'react'
import { SCFloatMenu } from './styles'
import { FiMenu } from 'react-icons/fi'
import { useCollapsed } from './../../layout/context/Collapsed'

const FloatMenu = () => {
    const { setIsToggled } = useCollapsed()

    return (
        <SCFloatMenu
            onClick={(value) => setIsToggled(value)}
        >
            <FiMenu size={30} color="#FFF" />
        </SCFloatMenu>
    )
}

export default FloatMenu
