const dark = {
    title: 'dark',
    colors: {
        primary: '#333',
        secondary: '#a76ab6',
        background: '#222',
        color: '#FFF'
    }
}

export default dark
