import styled from 'styled-components'

export const SCFloatMenu = styled.button`
    background-color: ${props => props.theme.colors.secondary};
    border: none;
    outline: none;
    position: fixed;
    right: 2%;
    top: 1.2%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    visibility: hidden;
    z-index: 1;
    @media (min-width: 0px) and (max-width: 768px) {
        visibility: visible;
    }
    &:active {
        outline: none;
    }
    &:focus {
        outline: none;
    }
`
