import React, { Suspense, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { publicRoutes, privateRoutes } from 'src/pages'
import Layout from 'src/layout'
import { useAuth } from 'src/context/Auth'
import Loading from 'src/components/LoadingWithText'
const Navigation = () => {
    const { userData } = useAuth()
    const { push } = useHistory()
    useEffect(() => {
        if (userData && !userData.RESET_PASSWORD) {
            setTimeout(() => {
                push('/reset-password')
            }, 1000)
        }
    }, [userData])
    return (<>
        <Suspense fallback={<Loading />}>
            <Switch>

                {publicRoutes.map((publicRoute) =>
                    <Route
                        key={publicRoute.name}
                        path={publicRoute.path}
                        component={publicRoute.component}
                        exact
                    />
                )}

                {userData && <Layout>
                    {
                        privateRoutes.map((privateRoute) => (
                            <Route
                                key={privateRoute.name}
                                path={privateRoute.path}
                                component={privateRoute.component}
                                exact
                            />
                        ))
                    }
                    <Route path="/*">
                        <Redirect to="/home" />
                    </Route>
                </Layout>
                }

                <Route path="/*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Suspense>
    </>)
}

export default Navigation
