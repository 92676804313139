import useSWR from 'swr'
import { dotnet, noheader } from 'src/consts'

export default (url, api = 'dotnet') => {
    const { data, error } = useSWR(url, async (url) => {
        if (url === 'ping') {
            const { data } = await noheader.get(url)
            return data
        } else if (api === 'dotnet') {
            const { data } = await dotnet.get(url)
            return data
        }
    }, {
        refreshWhenHidden: true,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
        refreshInterval: 5000
    })
    return { data, error }
}
