/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'src/components'
import { Menu, MenuItem } from 'react-pro-sidebar'
import { useCollapsed } from './../../layout/context/Collapsed'
import { IconCollapsed, IconUncollapsed, SCSidebarContent } from './styles'
import { IconHome, IconGrid, IconUsers, IconSol, Row, Badge } from 'src/layout/Sidebar/styles'
import { useAuth } from 'src/context/Auth'
import useGetData from 'src/hooks/useGetData'

const Content = ({ setMenuSelected }) => {
    const { userData: { ID_TYPE_USER, ID_USER } } = useAuth()
    const { data: modules } = useGetData(`prometeo/type-user-modules/modulesByTypeUser/${ID_TYPE_USER}`)
    const { data: solicitudes } = useGetData(`Solicitud/obtenerCountSolicitudes/${ID_USER}`, 'dotnet')
    const { isSidebarCollapsed, setIsSidebarCollapsed } = useCollapsed()
    const _handleSidebarCollapsed = () => setIsSidebarCollapsed(!isSidebarCollapsed)

    if (!modules) {
        return (
            <Spinner />
        )
    }

    return (
        <SCSidebarContent>
            <Menu iconShape="round">
                <MenuItem
                    icon={<IconHome />}
                    title='Inicio'
                    onClick={() => setMenuSelected(true)}>
                    Home
                    <Link to="/home" />
                </MenuItem>
                {
                    modules.includes('Aplicativos') &&
                    <MenuItem
                        icon={<IconGrid />}
                        title='Aplicativos'
                        onClick={() => setMenuSelected(true)}>
                        Aplicativos
                        <Link to="/apps/home" />
                    </MenuItem>
                }

                {
                    modules.includes('Aplicativos') &&
                    <MenuItem
                        icon={<IconSol />}
                        title='Solicitudes'
                        onClick={() => setMenuSelected(true)}>
                        <Link to="/solicitud" />
                        <Row>
                            Solicitudes
                            {solicitudes != null && solicitudes !== 0 &&
                                <Badge
                                    variant='warning'
                                >{solicitudes}</Badge>
                            }
                        </Row>
                    </MenuItem>
                }
                {
                    modules.includes('Aplicativos') &&
                    (ID_TYPE_USER === 2) &&
                    <MenuItem
                        icon={<IconUsers />}
                        title='flex'
                        onClick={() => setMenuSelected(true)}>
                        <Link to="/users" />
                        Crear Usuario
                    </MenuItem>
                }
                <MenuItem
                    title='Colapsar'
                    icon={isSidebarCollapsed ? <IconUncollapsed /> : <IconCollapsed />}
                    onClick={() => _handleSidebarCollapsed()}
                >
                    Colapsar
                </MenuItem>
            </Menu>
        </SCSidebarContent>
    )
}

export default Content
