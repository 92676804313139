const light = {
    title: 'light',
    colors: {
        primary: '#FFF',
        secondary: '#a76ab6',
        background: '#EEE',
        color: '#000'
    }
}

export default light
