/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react'
import { dotnet } from 'src/consts'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'
const AuthContext = createContext()

export default function AuthProvider ({ children }) {
    const { push } = useHistory()
    const debouncePush = debounce((path) => {
        setToken(null)
        setIsLogged(false)
        setUserData(null)
        push(path)
    }, 500)

    const [type, setType] = useState('login')
    const [isLogged, setIsLogged] = useState(false)
    const [codeCountry, setCodeCountry] = useState('CL')
    const [token, setToken] = useState(null)
    const [userData, setUserData] = useState(null)
    const cambiarInterceptor = useCallback(() => {
        // limpiar interceptors anteriores
        dotnet.interceptors.request.handlers = []
        dotnet.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${token}`
            config.headers['Access-Control-Allow-Origin'] = '*'
            return config
        })
        dotnet.interceptors.response.use(
            config => config,
            error => {
                if (error.response && error.response.status === 401 && error.response.data === 'Token expirado') {
                    debouncePush('/expired-session')
                }
                return Promise.reject(error)
            }
        )
    }, [token])

    useEffect(() => {
        cambiarInterceptor()
    }, [token])

    return (
        <AuthContext.Provider
            value={{
                type,
                setType,
                isLogged,
                setIsLogged,
                codeCountry,
                setCodeCountry,
                token,
                setToken,
                userData,
                setUserData
            }}
        >
            { children }
        </AuthContext.Provider>
    )
}

export function useAuth () {
    const {
        type, setType,
        isLogged, setIsLogged,
        codeCountry, setCodeCountry,
        token, setToken,
        userData, setUserData
    } = useContext(AuthContext)

    return {
        type,
        setType,
        isLogged,
        setIsLogged,
        codeCountry,
        setCodeCountry,
        token,
        setToken,
        userData,
        setUserData
    }
}
