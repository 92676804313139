import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 2%;
`
