import React from 'react'
import { Redirect } from 'react-router-dom'
import { Container, Content } from './styles'
import { useAuth } from 'src/context/Auth'
import Sidebar from './Sidebar'
import FloatMenu from './FloatMenu'
import CollapsedProvider from './context/Collapsed'
import PropTypes from 'prop-types'
const Layout = ({ children }) => {
    const { isLogged } = useAuth()

    if (!isLogged) {
        return (
            <Redirect to="/" />
        )
    }
    return (
        <Container>
            <CollapsedProvider>
                <FloatMenu />
                <Sidebar />
                <Content>
                    {children}
                </Content>
            </CollapsedProvider>
        </Container>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
