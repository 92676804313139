/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from 'react'
import { useAuth } from './Auth'
import { useHistory } from 'react-router-dom'
import { gapi } from 'gapi-script'
import { GoogleData, noheader } from '../consts'
import { useGoogleLogout, useGoogleLogin } from 'react-google-login'
import { Notification } from '../components/Notification'

export const GoogleContext = createContext()

const GoogleProvider = ({ children }) => {
    const configurationLogIn = {
        onSuccess: signIngoogleSuccess,
        onFailure: SignIngoogleFailure,
        isSignedIn: true
    }
    const configurationLogOut = {
        onLogoutSuccess: signOutgoogleSuccess,
        onFailure: SignOutgoogleFailure
    }
    const comonConfiguration = {
        cookiePolicy: 'single_host_origin',
        clientId: GoogleData.client_ID,
        scope: 'profile email' // Asegúrate de definir el scope
    }

    const { setToken, setIsLogged, setUserData } = useAuth()
    const { push } = useHistory()
    const [user, setUser] = useState(null)
    const [loadingLogin, setLoadingLogin] = useState(true)
    const [validandoInterno, setValidandoInterno] = useState(false)
    const [loadingLogOut, setLoadingLogOut] = useState(false)

    const { signIn, loaded: loadedLogIn } = useGoogleLogin({
        ...comonConfiguration,
        ...configurationLogIn
    })
    const { signOut, loaded: loadedLogOut } = useGoogleLogout({
        ...comonConfiguration,
        ...configurationLogOut
    })

    useEffect(() => {
        initGoogle()
    }, [])

    useEffect(() => {
        if (loadedLogIn) {
            setLoadingLogin(false)
        }
    }, [loadedLogIn])

    const initGoogle = async () => {
        await gapi.load('client:auth2', () => {
            gapi.client.init({
                clientId: GoogleData.client_ID,
                scope: 'profile email' // Asegúrate de definir el scope
            })
        })
    }

    async function signIngoogleSuccess(res) {
        const { tokenId } = res
        try {
            setValidandoInterno(true)
            const res = await noheader.post('auth-Google', { Token: tokenId })
            if (res && res.data && res.data) {
                Ingresar(res.data)
            }
            setValidandoInterno(false)
        } catch (error) {
            console.log('Error logging in with Google:', error)
        } finally {
            setLoadingLogin(false)
        }
    }

    function SignIngoogleFailure(error) {
        console.log('Error logging in with Google:', error)
        setLoadingLogin(false)
    }

    async function signOutgoogleSuccess() {
        try {
            setLoadingLogOut(true)
            await Salir()
        } catch (error) {
            console.log('Error signOut in with Google:', error)
        } finally {
            setLoadingLogOut(false)
        }
    }

    function SignOutgoogleFailure(error) {
        console.log('Error signOut in with Google:', error)
        setLoadingLogOut(false)
    }
    function Ingresar(data) {
        const { Token, userData } = data
        setToken(Token)
        sessionStorage.setItem('token', Token)
        setIsLogged(true)
        setUser({ userData, Token })
        setUserData(userData)
        push('/home')

    }
    async function Salir() {
        const res = await noheader.post('logout', { Token: user.Token })
        if (res && res.status >= 200 && res.status <= 299) {
            setUser(null)
            setIsLogged(false)
            setUserData(null)
            setToken(null)
            push('/')
        } else {
            Notification({
                type: 'error',
                text: 'Error al cerrar session de Prometeo'
            })
        }

    }
    return (
        <GoogleContext.Provider
            value={{
                user,
                loadingLogin,
                loadingLogOut,
                validandoInterno,
                signIn,
                signOut,
                loadedLogIn,
                loadedLogOut,
                Ingresar,
                Salir
            }}
        >
            {children}
        </GoogleContext.Provider>
    )
}

export const useGoogleContext = () => {
    const {
        user,
        loading,
        signIn,
        signOut,
        loadingLogin,
        validandoInterno,
        loadingLogOut,
        loadedLogIn,
        loadedLogOut,
        Ingresar,
        Salir
    } = useContext(GoogleContext)
    return {
        user,
        loading,
        signIn,
        signOut,
        loadedLogIn,
        loadedLogOut,
        loadingLogin: loadingLogin || validandoInterno,
        loadingLogOut,
        Ingresar,
        Salir
    }
}

export default GoogleProvider
