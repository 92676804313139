import { lazy } from 'react'
export const Button = lazy(() => import('./Button'))
export const CheckBox = lazy(() => import('./CheckBox'))
export const CheckboxForm = lazy(() => import('./CheckboxForm'))
export const DataTable = lazy(() => import('./DataTable'))
export const Flag = lazy(() => import('./Flag'))
export const Form = lazy(() => import('./Form'))
export const Image = lazy(() => import('./Image'))
export const Input = lazy(() => import('./Input'))
export const Label = lazy(() => import('./Label'))
export const Loading = lazy(() => import('./Loading'))
export const News = lazy(() => import('./News'))
export const PasswordInput = lazy(() => import('./PasswordInput'))
export const Select = lazy(() => import('./Select'))
export const SelectForm = lazy(() => import('./SelectForm'))
export const Separator = lazy(() => import('./Separator'))
export const Spinner = lazy(() => import('./Spinner'))
export const Stars = lazy(() => import('./Stars'))
export const Switch = lazy(() => import('./Switch'))
export const TextArea = lazy(() => import('./TextArea'))
export const Title = lazy(() => import('./Title'))
export const Wrapper = lazy(() => import('./Wrapper'))
export const VTable = lazy(() => import('./VTable/VTable'))
export const Modal = lazy(() => import('./Modal'))
export const LoadingWithText = lazy(() => import('./LoadingWithText'))
