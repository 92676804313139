import Noty from 'noty'

export const Notification = ({ type, text, confirmDialog }) => {
    // eslint-disable-next-line prefer-const
    let notify

    const _handleClose = () => notify.close()

    notify = new Noty({
        type,
        theme: 'bootstrap-v4',
        layout: 'topRight',
        text,
        buttons: confirmDialog ? buttons(_handleClose, confirmDialog) : null,
        timeout: 3000
    })
    notify.show()
}

const buttons = (_handleClose, confirmDialog) => {
    const {
        confirmText,
        actionConfirm
    } = confirmDialog

    return (
        [
            Noty.button('No', 'btn btn-danger', function () {
                _handleClose()
            }),
            Noty.button(confirmText, 'btn btn-primary ml-1', function () {
                actionConfirm()
                _handleClose()
            })
        ]
    )
}
