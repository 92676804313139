import React from 'react'
import { Image } from './../../components'
import { HeaderSidebar } from './styles'
import { imgLogoPrometeo, imgLogoPrometeoMin } from './../../assets/img'
import { useCollapsed } from './../context/Collapsed'

const Header = () => {
    const { isSidebarCollapsed } = useCollapsed()

    return (
        <HeaderSidebar >
            {
                isSidebarCollapsed
                    ? <Image
                        source={imgLogoPrometeoMin}
                        height="40px"
                        style={{
                            margin: '10% 5%'
                        }}
                    />
                    : <Image
                        source={imgLogoPrometeo}
                        width="200px"
                        height="200px"
                        style={{
                            margin: '10% 5%'
                        }}
                    />
            }
        </HeaderSidebar>
    )
}

export default Header
