import React from 'react'

import { Wrapper, Text } from './styles'
import PropTypes from 'prop-types'
import { BeatLoader } from 'react-spinners'
const Aut = ({ text = '', style }) => {
    return (
        <Wrapper style={style}>
            <BeatLoader size={50} color="#a76ab6" />
            <Text>{text}</Text>
        </Wrapper>
    )
}
Aut.propTypes = {
    text: PropTypes.string,
    style: PropTypes.object
}
export default Aut
