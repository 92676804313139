import React, { useState, useEffect } from 'react'

import { ThemeProvider } from 'styled-components'
import { dark, light } from './styles/themes'
import { useAuth } from './context/Auth'
import Routes from './routes'
import GlobalStyle from './styles'
import GoogleProvider from './context/googleContext'
import { dotnet } from './consts'
import { useHistory } from 'react-router-dom'
const App = () => {
    const { userData, setUserData, setToken, setIsLogged } = useAuth()
    const [theme, setTheme] = useState()
    const { push } = useHistory()
    useEffect(() => {
        if (userData) {
            setTheme(userData.USER_CONFIG.THEME)
        } else {
            setTheme('dark')
        }
    }, [userData])

    useEffect(() => {
        const token = sessionStorage.getItem('token')
        if (token && token !== 'undefined' && token !== 'null' && token !== '') {
            dotnet.post('validatesesion', { Token: token })
                .then((resp) => {
                    setToken(token)
                    setUserData(resp.data.userData)
                    setIsLogged(true)
                    push('/home')
                })
                .catch((error) => {
                    console.log(error)
                    setUserData(null)
                    setToken(null)
                    sessionStorage.removeItem('token')
                })
        }
    }, [])

    return (
        <GoogleProvider>
            <ThemeProvider theme={theme === 'dark' ? dark : light}>
                <GlobalStyle />
                <Routes />
            </ThemeProvider>
        </GoogleProvider>
    )
}

export default App
