/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import Header from './header.sidebar'
import Content from './content.sidebar'
import Footer from './footer.sidebar'
import { SCSidebar } from './styles'
import 'react-pro-sidebar/dist/css/styles.css'
import { useCollapsed } from './../../layout/context/Collapsed'
import { useAuth } from './../../context/Auth'
import { Redirect } from 'react-router'

const Sidebar = () => {
    const { userData } = useAuth()
    const { isSidebarCollapsed, setIsSidebarCollapsed, isToggled, setIsToggled } = useCollapsed()
    const [menuSelected, setMenuSelected] = useState(null)

    const handleSidebar = value => {
        setIsSidebarCollapsed(value)
        setIsToggled(value)
    }

    useEffect(() => {
        if (menuSelected) {
            const keep = userData.USER_CONFIG.KEEP_SIDEBAR
            if (keep) {
                handleSidebar(true)
                setMenuSelected(null)
            }
        }
    }, [menuSelected])

    if (!userData) {
        return (
            <Redirect to="/" />
        )
    }
    return (
        <SCSidebar
            collapsed={isSidebarCollapsed}
            toggled={isToggled}
            breakPoint="md"
            onToggle={(value) => setIsToggled(value)}
        >
            <Header />
            <Content setMenuSelected={setMenuSelected} />
            <Footer setMenuSelected={setMenuSelected} />
        </SCSidebar>
    )
}

export default Sidebar
