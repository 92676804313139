/* eslint-disable*/
import React, { createContext, useState, useContext } from 'react'
import { useAuth } from 'src/context/Auth'

const CollapsedContext = createContext()

export default function CollapsedProvider({ children }) {
    const { userData: { USER_CONFIG: { KEEP_SIDEBAR } } } = useAuth()
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(KEEP_SIDEBAR ?? false)
    const [isToggled, setIsToggled] = useState(false)
    return (
        <CollapsedContext.Provider
            value={{
                isSidebarCollapsed,
                setIsSidebarCollapsed,
                isToggled,
                setIsToggled
            }}
        >
            {children}
        </CollapsedContext.Provider>
    )
}

export function useCollapsed() {
    const {
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isToggled,
        setIsToggled
    } = useContext(CollapsedContext)

    return {
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isToggled,
        setIsToggled
    }
}
